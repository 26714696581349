var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('v-tabs',{attrs:{"color":"#519043","align-tabs":"left"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabList),function(tab){return _c('v-tab',{key:tab.id,on:{"click":function($event){return _vm.changeTab(tab)}}},[_vm._v(_vm._s(tab.name))])}),1)],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","prepend-inner-icon":"search","label":"Search","single-line":"","hide-details":"","color":"#239FAB","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card',{staticClass:"ma-5 dt-container",attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":10,"search":_vm.search,"options":_vm.options,"loading":_vm.loading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"item.to_names",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._f("highlight")(item.to_names,_vm.search)}})]}},{key:"item.travel_date_from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.isValidJSON(item.travel_date_from) ? _vm.formatDate(_vm.getFirstDate(item.travel_date_from)) : _vm.formatDate(item.travel_date_from))+" - "+_vm._s(_vm.formatDate(item.travel_date_to))+" ")]}},{key:"item.is_recommending_approval_approved",fn:function(ref){
var item = ref.item;
return [(item.is_recommending_approval_approved == 1)?_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":""}},[_vm._v(" For Approval ")]):_vm._e(),(item.is_recommending_approval_approved == 2)?_c('v-icon',{attrs:{"color":"#519043"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.is_recommending_approval_approved == 3)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-timer-refresh-outline")]):_vm._e()]}},{key:"item.is_finance_dir_approved",fn:function(ref){
var item = ref.item;
return [(item.is_finance_dir_approved == 1)?_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":""}},[_vm._v(" For Approval ")]):_vm._e(),(item.is_finance_dir_approved == 2)?_c('v-icon',{attrs:{"color":"#519043"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.is_finance_dir_approved == 3)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-timer-refresh-outline")]):_vm._e()]}},{key:"item.is_president_approved",fn:function(ref){
var item = ref.item;
return [(item.is_president_approved == 1)?_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":""}},[_vm._v(" For Approval ")]):_vm._e(),(item.is_president_approved == 2)?_c('v-icon',{attrs:{"color":"#519043"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.is_president_approved == 3)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-timer-refresh-outline")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"x-small":"","color":"grey","outlined":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-eye")]),_vm._v(" View ")],1)]}}],null,true)})],1),_c('v-row',{staticClass:"mb-2 mx-5",attrs:{"align":"center"}},[_c('v-col',{staticClass:"mr-auto text-truncate flex-items",attrs:{"cols":"auto","no-gutters":""}},[_c('span',{staticClass:"px-2"},[_vm._v("Show")]),_c('span',[_c('v-select',{staticClass:"rounded-lg",staticStyle:{"max-width":"90px"},attrs:{"dense":"","outlined":"","color":"#519043","hide-details":"","value":_vm.options.itemsPerPage,"items":_vm.perPageChoices},on:{"change":function($event){_vm.options.itemsPerPage = parseInt($event, 10)}}})],1),_c('span',{staticClass:"px-2"},[_vm._v(" Entries ")])]),_c('v-col',{staticClass:"mr-auto text-truncate",attrs:{"cols":"auto","no-gutters":""}},[_vm._v(" Showing "+_vm._s(_vm.paginationData.pageStart + 1)+" to "+_vm._s(_vm.paginationData.pageStop)+" of "+_vm._s(_vm.paginationData.itemsLength)+" entries ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-pagination',{staticClass:"rounded-lg",attrs:{"total-visible":7,"color":"#519043","length":_vm.paginationData.pageCount},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1)],1),_c('ViewAuditTravelOrder',{attrs:{"data":_vm.updateData,"action":_vm.action,"tab":_vm.tab}}),_c('ViewTravelOrderToUpdateDialog',{attrs:{"data":_vm.viewData,"action":_vm.action}}),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }